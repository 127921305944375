export enum SCHEDULE_RESTRICTIONS_FILTERS {
  RESTRICTION_TYPE = 'Restriction Type',
  RESTRICTING_ENTITY = 'Restricting Entity',
  DEPARTURE_LEVEL = 'Departure Level',
  DEPARTURE_ENTITY = 'Departure Entity',
  ARRIVAL_LEVEL = 'Arrival Level',
  ARRIVAL_ENTITY = 'Arrival Entity',
  OVERFLIGHT_LEVEL = 'Overflight Level',
  OVERFLIGHT_ENTITY = 'Overflight Entity',
  FAR_TYPE = 'FAR Types',
  STATUS = 'Status',
}

export enum SCHEDULE_RESTRICTIONS_LEVEL {
  AIRPORT = 'Airport',
  COUNTRY = 'Country',
  STATE = 'State',
  FIR = 'FIR',
  FAR_TYPE = 'farTypes',
}
