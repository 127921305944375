export const apiUrls = {
  uwaAllowableAction: 'api/UWAAllowableAction',
  uwaAllowableService: 'api/UWAAllowableService',
  restrictionType: 'api/RestrictionType',
  landingOrOverFlight: 'api/LandingOrOverFlight',
  approvalType: 'api/ApprovalTypeRequired',
  restrictionApplied: 'api/RestrictionApplied',
  restrictionSource: 'api/RestrictionSource',
  restrictionLevel: 'api/RestrictionLevel',
  sourceType: 'api/SourceType',
  accessLevel: 'api/AccessLevel',
  healthAuth: 'api/HealthAuthorization',
  healthAuthById: healthAuthId => `api/HealthAuthorization/${healthAuthId}`,
  authorizationLevel: 'api/AuthorizationLevel',
  affectedType: 'api/AffectedType',
  infectionType: 'api/InfectionType',
  referenceData: 'api/referenceData',
  healthAuthValidateUnique: 'api/HealthAuthorization/ValidateUnique',
  quarantineLocation: 'api/QuarantineLocation',
  testType: 'api/TestType',
  healthForm: 'api/HealthForm',
  leadTimeIndicator: 'api/LeadTimeIndicator',
  healthVendor: 'api/HealthVendor',
  healthVendorById: healthVendorId => `api/HealthVendor/${healthVendorId}`,
  contactLevel: 'api/ContactLevel',
  flightsAllowed: 'api/FlightsAllowed',
  whoCanLeaveAircraft: '/api/WhoCanLeaveAircraft',
  vaccinationPrivilege: '/api/VaccinationPrivilege',
  overFlightLevel: 'api/ScheduleOverFlightLevel',
  vaccineManufacturers: '/api/VaccineManufacturer',
  boardingType: 'api/BoardingType',
  arrivalLevel: 'api/ScheduleArrivalLevel',
  scheduleDepartureLevel: 'api/ScheduleDepartureLevel',
  ppeType: '/api/PPEType',
  scheduleRestriction: 'api/ScheduleRestriction',
  aircraftOperatorRestriction: 'api/AircraftOperatorRestriction',
  stayLengthCategory: 'api/StayLengthCategory',
  generalInformation: id => `api/HealthAuthorization/${id}/generalInformation`,
  entryRequirement: (id: number) => `api/HealthAuthorization/${id}/entryRequirement`,
  domesticMeasure: (id: number) => `api/HealthAuthorization/${id}/domesticmeasure`,
  exitRequirement: (id: number) => `api/HealthAuthorization/${id}/exitRequirement`,
  vaccinationRequirement: (id: number) => `api/HealthAuthorization/${id}/vaccinationRequirement`,
  changeRecord: (id: number) => `api/HealthAuthorization/${id}/changeRecords`,
  stayRequirement: (id: number) => `api/HealthAuthorization/${id}/stayRequirement`,
  curfewHourType: 'api/CurfewHourType',
  quarantineRequirement: (id: number) => `api/HealthAuthorization/${id}/quarantineRequirement`,
  idType: 'api/IdType',
  aircraftOperatorRestrictionType: 'api/AircraftOperatorRestrictionType',
  effectedEntityType: 'api/EffectedEntityType',
  enforcementAgency: 'api/EnforcementAgency',
  restrictionSeverity: 'api/RestrictionSeverity',
  approvalTypeRequired: 'api/ApprovalTypeRequired',
  healthAuthExcel: 'api/HealthAuthorization/excel',
  restrictionForm: 'api/RestrictionForm',
  audit: 'api/AuditHistory',
  healthAuthorizationClone: 'api/HealthAuthorization/clone',
  traveledHistory: (id: number) => `api/HealthAuthorization/${id}/traveledHistory`,
  traveledHistoryCategory: 'api/TraveledHistoryCategory',
  traveledHistorySubCategory: 'api/TraveledHistorySubCategory',
  travellerType: 'api/TravellerType',
  vaccinationStatus:'api/VaccinationStatus',
};
