import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  editorWrapperContainer: {
    overflow: 'auto',
  },
  headerActionsEditMode: {
    justifyContent: 'space-between',
  },
  summary: {
    flexBasis: '100%',
  },
});
