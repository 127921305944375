export enum HEALTH_AUTH_TABS {
  GENERAL_INFO = 'General Info',
  GENERAL_REQUIREMENT = 'General Requirement',
  ENTRY_REQUIREMENT = 'Entry Requirement',
  QUARANTINE_REQUIREMENT = 'Quarantine Requirement',
  CHANGE_RECORD = 'Change Records',
  EXIT_REQUIREMENT = 'Exit Requirement',
  VACCINE_REQUIREMENT = 'Vaccine Requirement',
  STAY_REQUIREMENT = 'Stay Requirement',
  DOMESTIC_MEASURE = 'Domestic Measure',
}
