export enum HEALTH_AUTH_FILTERS {
  LEVEL_DESIGNATOR = 'Level Designator',
  NATIONALITY = 'Nationality',
  TRAVELED_COUNTRY = 'Traveled Country',
  STATUS = 'Status',
}

export enum AUTHORIZATION_LEVEL {
  AIRPORT = 'Airport',
  COUNTRY = 'Country',
  STATE = 'State',
}

export enum AFFECTED_TYPE {
  NATIONALITY = 'Nationality',
  TRAVELED_COUNTRY = 'Travel History',
  BOTH = 'Both',
}
