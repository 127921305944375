export enum SETTING_CATEGORIES {
  RESTRICTION = 1,
  HEALTH_DATA,
  GENERAL,
  AIRCRAFT_OPERATOR,
}

export enum SETTING_ID {
  UWA_ALLOWABLE_ACTION = 1,
  UWA_ALLOWABLE_SERVICE,
  LANDING_OR_OVERFLIGHT,
  RESTRICTION_TYPE,
  APPROVAL_TYPE,
  RESTRICTION_APPLIES,
  RESTRICTION_SOURCE,
  RESTRICTION_LEVEL,
  AUTHORIZATION_LEVEL,
  INFECTION_TYPE,
  AFFECTED_TYPE,
  SOURCE_TYPE,
  ACCESS_LEVEL,
  QUARANTINE_LOCATION,
  HEALTH_FORM,
  TEST_TYPE,
  LEAD_TIME_INDICATOR,
  CONTACT_LEVEL,
  STATUS,
  FLIGHTS_ALLOWED,
  WHO_CAN_LEAVE_AIRCRAFT,
  VACCINATION_PRIVILEGE,
  OVERFLIGHT_LEVEL,
  VACCINE_MANUFACTURER,
  ARRIVAL_LEVEL,
  BOARDING_TYPE,
  DEPARTURE_LEVEL,
  STAY_LENGTH_CATEGORY,
  CURFEW_HOUR_TYPE,
  PPE_TYPE,
  ID_TYPE,
  AIRCRAFT_OPERATOR_RESTRICTION_TYPE,
  EFFECTED_ENTITY_TYPE,
  ENFORCEMENT_AGENCY,
  RESTRICTION_SEVERITY,
  APPROVAL_TYPE_REQUIRED,
  RESTRICTION_FORM,
  TRAVELED_HISTORY_CATEGORY,
  TRAVELED_HISTORY_SUB_CATEGORY,
  TRAVELLER_TYPE,
  VACCINATION_STATUS,
}
